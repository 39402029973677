<template>
  <div>
    <Loading v-if="loading" />
    <v-container>
      <v-row class="mt-2">
        <v-col cols="12" class="py-0">
          <div class="d-flex justify-space-between">
            <h2 class="text-secondary header">
              เพิ่มแอดมิน {{ $route.params.slug }}
            </h2>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-row>
      <v-col cols="12">
        <v-card color="px-6 py-6 mt-8">
          <v-form ref="form" @submit.prevent="onSaveData()">
            <v-text-field
              :rules="[notEmptyRules]"
              v-model="form.username"
              label="ชื่อผู้ใช้"
            />
            <v-text-field
              v-model="form.password"
              label="รหัสผ่าน"
              :rules="[notEmptyRules]"
              :type="isOpenPassword ? 'text' : 'password'"
              :append-icon="isOpenPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="isOpenPassword = !isOpenPassword"
            />
            <v-text-field
              v-model="confirmPassword"
              label="ยืนยันรหัสผ่าน"
              :rules="[notEmptyRules]"
              :type="isOpenPassword ? 'text' : 'password'"
              :append-icon="isOpenPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="isOpenPasswordConfirm = !isOpenPasswordConfirm"
            />
            <v-text-field
              :rules="[notEmptyRules]"
              v-model="form.firstname"
              label="ชื่อจริง"
            />
            <v-text-field
              :rules="[notEmptyRules]"
              v-model="form.lastname"
              label="นามสกุล"
            />
            <v-select
              v-model="form.branch"
              :items="branchItems"
              label="สาขา"
            ></v-select>
            <div class="text-right mt-4">
              <v-btn color="green" dark class="font-weight-bold" type="submit"
                >บันทึก</v-btn
              >
            </div>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Loading from "@/components/Loading.vue";
import axios from "../../../utils/axios.js";
export default {
  components: {
    Loading,
  },
  computed: {
    notEmptyRules() {
      return (value) => !!value || "กรุณากรอกข้อมูล";
    },
    branchItems() {
      return [
        { text: "ตรัง", value: "trang" },
        { text: "สาทร", value: "sathorn" },
        // { text: "ทั้งหมด", value: "all" },
      ];
    },
    ...mapGetters({
      info: "User/info",
    }),
  },
  data() {
    return {
      loading: false,
      changePassword: false,
      isOpenPassword: false,
      isOpenPasswordConfirm: false,
      confirmPassword: "",
      form: {
        username: "",
        password: "",
        firstname: "",
        lastname: "",
        branch: "trang",
      },
    };
  },
  mounted() {
    if (this.info && this.info.profile.id !== process.env.VUE_APP_AUTH_ID) {
      this.$router.push("/");
    }
  },
  methods: {
    async onSaveData() {
      try {
        const valid = this.$refs.form.validate();
        if (valid) {
          this.loading = true;
          await axios.post(`/auth/create-admin`, this.form);
          this.alertSuccess("ดำเนินการสำเร็จ");
        }
      } catch (error) {
        this.alertError("เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง");
      } finally {
        this.loading = false;
      }
    },
    alertSuccess(text) {
      this.$toast.success(text, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: false,
        icon: true,
        rtl: false,
      });
    },
    alertError(text) {
      this.$toast.error(text, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: false,
        icon: true,
        rtl: false,
      });
    },
  },
};
</script>
